/* eslint-disable object-shorthand */

export const PACKAGE_BUILD_DATE = '2025-01-10T01:34:25.821Z';
export const PACKAGE_NAME = 'invision-customercare';
export const PACKAGE_VERSION = '24.4.10502-1681673';

export default {
    PACKAGE_BUILD_DATE: PACKAGE_BUILD_DATE,
    PACKAGE_NAME: PACKAGE_NAME,
    PACKAGE_VERSION: PACKAGE_VERSION
};
